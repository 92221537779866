import { cloneDeep } from 'lodash';

function compareKeys(a, b) {
  for (const key of Object.keys(a)) {
    if (a[key] !== b[key]) {
      return true;
    }
  }
  return false;
}

export async function updateResources(holderEntityId, holderEntityService, oldResources, newResources) {
  const result = {
    hasError: false,
    errorCodes: []
  }
  const resources = cloneDeep(newResources);
  const originResources = oldResources;
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  for (let i = 0, len = resources.length; i < len; i++) {
    const resource = resources[i];
    const index = originResources.findIndex(j => j.uuId === resource.uuId);
    if (index == -1) {
      toAdd.push(resource);
    } else {
      const originResource = originResources[index];
      if (compareKeys(resource, originResource)) {
        toUpdate.push(resource);
      } else {
        unchangedIds.push(resource.uuId);
      }
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originResources.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

    //To add new resources if there is any.
  if (toAdd.length > 0) {
    let { needUpdate = [], hasError = false, errorCode = null } = await holderEntityService.create(holderEntityId, toAdd)
    .then(response => {
      if (207 == response.status) {
        const list = response.data[response.data.jobCase];
        const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
        return {
          needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)),
          hasError: failIds.length > 0,
          errorCode: 207
        };
      }
      return {}
    })
    .catch(e => {
      if (e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        return {
          needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)),
          hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)).length > 0,
          errorCode: 422
        };
      } else {
        return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
      }
    });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
    //Collect those skills require Update (instead of add) and add them to toUpdate list.
    toUpdate.splice(toUpdate.length, 0, ...needUpdate);
  }

  //To update existing resources if there is any.
  if (toUpdate.length > 0) {
    let { needAdd=[], hasError=false, errorCode=null } = await holderEntityService.update(holderEntityId, toUpdate)
    .then(response => {
      if (207 == response.status) {
        const list = response.data[response.data.jobCase];
        const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
        const failIds = list.filter(i => i.clue !== 'Unknown_relation' && i.clue !== 'OK').map(i => i.args[0]);
        return { 
          needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), 
          hasError: failIds.length > 0,
          errorCode: 207 
        };
      }
      return {}
    })
    .catch(e => {
      if (e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
        return { 
          needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), 
          hasError: toUpdate.filter(i => !toAddIds.includes(i.uuId)).length > 0,
          errorCode: 422
        };
      } else {
        return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
      }
    });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
    
    //To add resources which require Add operation (instead of Update) if there is any.
    if (needAdd.length > 0) {
      let { hasError=false, errorCode=null } = await holderEntityService.create(holderEntityId, needAdd)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
            return { hasError: failIds.length > 0, errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            return { 
              needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
              hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)).length > 0,
              errorCode: 422
            };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
      if (hasError) {
        result.hasError = true;
        result.errorCodes.push(errorCode);
      }
    }
  }

  //Try remove resources which user wants to remove if there is any
  if (toRemove.length > 0) {
    const clues = ['OK', 'Unknown_relation'];
    let { hasError=false, errorCode=null } = await holderEntityService.remove(holderEntityId, toRemove)
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 207 };
        }
        return {};
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 422 };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        }
      });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  return result;
}