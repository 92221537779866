var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.title,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.hidden()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.allowSelect
                    ? [
                        _vm.canEdit() || !_vm.exists
                          ? _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: { click: _vm.ok },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SELECT" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12", md: "2" } },
                    [
                      _c("b-form-radio-group", {
                        staticClass: "preview-state-toggler",
                        attrs: {
                          options: [
                            {
                              text: _vm.$t("comment.button.write"),
                              value: false,
                            },
                            {
                              text: _vm.$t("comment.button.preview"),
                              value: true,
                            },
                          ],
                          buttons: "",
                          "button-variant": "outline-secondary",
                          size: "sm",
                        },
                        model: {
                          value: _vm.preview,
                          callback: function ($$v) {
                            _vm.preview = $$v
                          },
                          expression: "preview",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pl-md-0 pr-0",
                      attrs: { cols: "12", "offset-md": "4", md: "6" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mt-2 mt-md-0",
                          attrs: {
                            label: _vm.$t("field.identifier"),
                            "label-for": "identifier",
                            "label-align-md": "right",
                            "label-cols-md": "3",
                            "content-cols-md": "9",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "identifier",
                                  type: "text",
                                  "data-vv-as": _vm.$t("field.identifier"),
                                  "data-vv-name": "localNote.identifier",
                                  maxlength: _vm.maxIdentifierLength,
                                  disabled: _vm.isReadOnly,
                                  size: "",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.localNote.identifier,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localNote, "identifier", $$v)
                                  },
                                  expression: "localNote.identifier",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["identifier"] != null
                    ? _vm._l(
                        _vm.customFieldMap["identifier"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "identifier" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.localNote[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localNote, field.name, $$v)
                                      },
                                      expression: "localNote[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("b-textarea", {
            ref: "textarea",
            staticClass: "comment-textarea rounded-0",
            class: { "d-none": _vm.previewState },
            attrs: {
              refs: "commentInput",
              name: "note",
              id: "message",
              rows: "9",
              placeholder: _vm.$t("comment.placeholder.your_comment"),
              trim: "",
              required: "",
              autofocus: "",
            },
            model: {
              value: _vm.localNote.text,
              callback: function ($$v) {
                _vm.$set(_vm.localNote, "text", $$v)
              },
              expression: "localNote.text",
            },
          }),
          _c("div", {
            staticClass: "preview markdown-body",
            class: { "d-none": !_vm.previewState },
            domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
          }),
          _c(
            "div",
            { staticClass: "markdown-hint" },
            [
              _c(
                "font-awesome-layers",
                { staticClass: "fa-lg info-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle"], transform: "shrink-2" },
                  }),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "info"], transform: "shrink-8" },
                  }),
                ],
                1
              ),
              _c("span", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://projectal.com/resources/markdown",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("comment.link.markdown")))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("comment.link.is_supported"))),
              ]),
            ],
            1
          ),
          _vm.customFieldMap["note"] != null ||
          _vm.customFieldMap["default"] != null
            ? _c(
                "div",
                { staticClass: "container pl-0 mt-3" },
                [
                  _c(
                    "b-row",
                    [
                      _vm.customFieldMap["note"] != null
                        ? _vm._l(
                            _vm.customFieldMap["note"],
                            function (field, index) {
                              return _c(
                                "b-col",
                                {
                                  key: "note" + index,
                                  staticClass: "pr-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      field.type !== "Boolean"
                                        ? _c("template", { slot: "label" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(field.displayName)
                                                ),
                                              ]
                                            ),
                                            field.description
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        color:
                                                          "var(--form-control-placeholder)",
                                                        fontSize: "0.9em",
                                                      },
                                                      attrs: {
                                                        id: `${_vm.componentId}_${field.name}`,
                                                        icon: [
                                                          "far",
                                                          "circle-question",
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target: `${_vm.componentId}_${field.name}`,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              field.description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("CustomField", {
                                        attrs: {
                                          componentId: _vm.componentId,
                                          field: field,
                                          disabled:
                                            _vm.isReadOnly ||
                                            (_vm.exists &&
                                              !_vm.canEdit(_vm.permissionName, [
                                                field.name,
                                              ])),
                                        },
                                        model: {
                                          value: _vm.localNote[field.name],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localNote,
                                              field.name,
                                              $$v
                                            )
                                          },
                                          expression: "localNote[field.name]",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                      _vm.customFieldMap["default"] != null
                        ? _vm._l(
                            _vm.customFieldMap["default"],
                            function (field, index) {
                              return _c(
                                "b-col",
                                {
                                  key: index,
                                  staticClass: "pr-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      field.type !== "Boolean"
                                        ? _c("template", { slot: "label" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(field.displayName)
                                                ),
                                              ]
                                            ),
                                            field.description
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        color:
                                                          "var(--form-control-placeholder)",
                                                        fontSize: "0.9em",
                                                      },
                                                      attrs: {
                                                        id: `${_vm.componentId}_${field.name}`,
                                                        icon: [
                                                          "far",
                                                          "circle-question",
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target: `${_vm.componentId}_${field.name}`,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              field.description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("CustomField", {
                                        attrs: {
                                          componentId: _vm.componentId,
                                          field: field,
                                          disabled:
                                            _vm.isReadOnly ||
                                            (_vm.exists &&
                                              !_vm.canEdit(_vm.permissionName, [
                                                field.name,
                                              ])),
                                        },
                                        model: {
                                          value: _vm.localNote[field.name],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localNote,
                                              field.name,
                                              $$v
                                            )
                                          },
                                          expression: "localNote[field.name]",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }