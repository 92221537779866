<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="title" footer-class="footerClass"
      @hidden="hidden()"
      content-class="shadow" no-close-on-backdrop
      scrollable
    >
    
      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col cols="12" md="2" class="pr-0">
            <b-form-radio-group class="preview-state-toggler"
                v-model="preview"
                :options="[{ text: $t('comment.button.write'), value: false }, { text: $t('comment.button.preview'), value: true }]"
                buttons
                button-variant="outline-secondary"
                size="sm"
              />
          </b-col>
        
          <b-col cols="12" offset-md="4" md="6" class="pl-md-0 pr-0">
            <b-form-group class="mt-2 mt-md-0" :label="$t('field.identifier')" label-for="identifier" label-align-md="right" label-cols-md="3" content-cols-md="9">
              <b-input-group>
                <b-form-input id="identifier" type="text"
                  :data-vv-as="$t('field.identifier')"
                  data-vv-name="localNote.identifier"
                  :maxlength="maxIdentifierLength"
                  v-model="localNote.identifier" 
                  :disabled="isReadOnly"
                  size=""
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['identifier'] != null">
            <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="localNote[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      

      <b-textarea ref="textarea" v-model="localNote.text"  refs="commentInput" name="note" id="message" rows="9"
        :placeholder="$t('comment.placeholder.your_comment')"
        class="comment-textarea rounded-0"
        :class="{ 'd-none': previewState }"
        trim required autofocus/>

      <div class="preview markdown-body" :class="{ 'd-none': !previewState }" v-html="compiledMarkdown">
      </div>

      <div class="markdown-hint">
        <font-awesome-layers class="fa-lg info-icon">
          <font-awesome-icon :icon="['far','circle']" transform="shrink-2" />
          <font-awesome-icon :icon="['far', 'info']" transform="shrink-8" />
        </font-awesome-layers>
        <span><a target="_blank" href="https://projectal.com/resources/markdown">{{ $t('comment.link.markdown') }}</a> {{ $t('comment.link.is_supported') }}</span>
      </div>

      <div v-if="customFieldMap['note'] != null || customFieldMap['default'] != null" class="container pl-0 mt-3">
        <b-row>
          <template v-if="customFieldMap['note'] != null">
            <b-col v-for="(field, index) in customFieldMap['note']" :key="'note'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="localNote[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="localNote[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template v-if="allowSelect">
          <b-button v-if="canEdit() || !exists" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
        
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import * as DOMPurify from 'dompurify';
import * as Marked from 'marked';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
// import { processRegExp } from '@/helpers';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

const renderer = new Marked.Renderer();
renderer.link = function(href, title, text) {
  const link = Marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a","<a target='_blank' ");
};
Marked.setOptions({
  renderer: renderer,
  gfm: true
});

export default {
  name: 'NoteModal',
  components: {
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:           { type: String,   default: 'NOTE_NEW' }
    , note:       { type: Object,   default: () => {} }
    , title:        { type: String,   default: function() { return this.$t('comment.button.add_comment'); } }
    , show:         { type: Boolean, required: true }
    , mode:         { type: String, default: 'BOTH' } //Possible value: ['SELECT', 'MANAGE', 'BOTH']
  },
  data() {
    return {
      permissionName: 'NOTE'
      , modelInfo: null
      , modalShow: false
      , alertMsg: null
      , localNote: {
          text: null
        , identifier: null
      }
      , preview: false
      
      , customFields: []
      , customFieldMap: {}
    }
  },
  created() {
    this.getModelInfo();
    this.modalShow = this.show;
    this.initializeNote();
  },
  beforeDestroy() {
    this.originNote = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        this.preview = false;
        this.alertMsg = null;
        this.initializeNote();
      }
    }
  },
  computed: {
    componentId() {
      return `NOTE_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    currentUser() {
      return this.$store.state.authentication.user;
    },
    exists() {
      return this.id && !this.id.startsWith('NOTE_NEW');
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    isReadOnly() {
      return this.mode && 'SELECT' === this.mode || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    errorShow() {
      return this.alertMsg != null;
    },
    compiledMarkdown: function () {
      if (this.localNote.text === null) {
        return '';
      }
      return DOMPurify.sanitize(Marked(this.localNote.text));
    },
    previewState() {
      return this.preview;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    }
  },
  methods: {
    async initializeNote() {
      this.localNote = {
        text: this.note.text,
        identifier: this.note.identifier
      }
      if (this.note.uuId != null) {
        this.localNote.uuId = this.note.uuId;
      }

      await getCustomFieldInfo(this, 'NOTE');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      
      for (const field of this.customFields) {
        if (typeof this.note[field.name] !== 'undefined') {
          this.$set(this.localNote, field.name,  this.note[field.name]);
        }
      }

      this.originNote = cloneDeep(this.localNote);
    }, 
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "NOTE"}).then(value => {
        this.modelInfo = value.NOTE.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    hidden() {
      this.$emit('update:show', false);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    async ok() {
      const data = {
        text: this.localNote.text
        , identifier: this.localNote.identifier
      }

      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.localNote[field.name])) {
          field.showError = true;
          return;  
        }
        if (typeof this.localNote[field.name] !== 'undefined') {
          data[field.name] = this.localNote[field.name];
        }
      }
      
      const isTextEmpty = this.localNote.text == null || this.localNote.text.trim().length == 0;
      const isIdentifierEmpty = this.localNote.identifier == null || this.localNote.identifier.trim().length == 0;

      if (this.localNote.uuId == null && isTextEmpty && isIdentifierEmpty) {
        this.hidden();
        return;
      }

      if (isTextEmpty && 
          (this.localNote.uuId != null || !isIdentifierEmpty)) {
        this.alertMsg = 'Comment cannot be empty';
        return;
      }

      let mode = 'create';
      if(this.localNote.uuId) {
        mode = 'update';
        data.uuId = this.localNote.uuId
      }

      if (mode == 'create') {
        data.authorRef = this.currentUser.uuId;
        data.author = this.currentUser.name;
        this.$emit('toAdd', data);
      } else {
        // we always emit all data because the identifier could have changed and if
        // we don't emit the text errors occur
        this.$emit('toUpdate', data);
      } 
      this.hidden();
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
                          && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists))
    }
  }
}
</script>

<style lang="scss">
  .preview {
    border: 1px solid var(--form-control-border);
    padding: 6px 12px;
    margin-top: 8px;
    min-height: 203px;
    height: 203px;
    overflow-y: auto;
    resize: vertical;
    background-color: var(--comment-bg);
  }

  .note-content {
    font-family: Arial;
    font-size: 14px;
  }

  .comment-textarea {
    margin-top: 8px;
  }

  .markdown-hint {
    position: relative;
    color: var(--bs-text-muted);
    margin-top: 5px;
    font-size: 0.75rem;

    .info-icon {
      position: absolute;
      left: 0;
      top: 1px;
    }
    span {
      padding-left: 20px;
    }
  }

  .preview-state-toggler {
    .btn.btn-outline-secondary.focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled):active:focus,
    .btn.btn-outline-secondary:not(:disabled):not(disabled).active:focus {
      box-shadow: none;
    }
  }
</style>